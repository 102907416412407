import {
	PrinterIcon,
	EditIcon,
	NotesIcon,
	DeleteIcon,
} from '../../../assets/Icons';
import { MessageBoxStateType } from '../../../@core-ui';
import {
	E_DeviceType,
	TYPES_OF_ELEMENTS,
} from '../../../constants/appConstants';
import { getDeviceType } from '../../../utilities/utils';
export const PORTFOLIO_HEADER = 'Portfolio Tracker';
export const CREATE_PORTFOLIO_HEADER = 'Create Portfolio';
export const EDIT_PORTFOLIO_HEADER = 'Edit Portfolio';
export const MAX_PORTFOLIO_TRANSACTIONS = 100;
const isMobile = getDeviceType() === E_DeviceType.Mobile;

export enum CREATE_EDIT_PORTFOLIO_KEYS {
	Name = 'name',
	Description = 'description',
	Id = 'id',
	InceptionDate = 'inceptionDate',
}

export type CREATE_EDIT_PORTFOLIO_TYPE = {
	[CREATE_EDIT_PORTFOLIO_KEYS.Id]?: string;
	[CREATE_EDIT_PORTFOLIO_KEYS.Name]: string;
	[CREATE_EDIT_PORTFOLIO_KEYS.Description]?: string;
	[CREATE_EDIT_PORTFOLIO_KEYS.InceptionDate]?: string;
	isPortfolioView?: boolean;
};

export const MAX_PORTFOLIO = 20;
export const CREATE_PORTFOLIO_LABELS = {
	PORTFOLIO_NAME: '*Portfolio Name',
	PORTFOLIO_NOTES: 'Portfolio Notes',
	REQUIRED: '*Required',
	ADD_TRANSACTION: 'Add a Transaction',
};

export const CREATE_PORTFOLIO_PLACEHOLDERS = {
	LIMIT: 'Limit name to 20 characters',
	NOTE: 'Add an optional note about your portfolio.',
};

export const PORTFOLIO_MESSAGE = {
	NO_NAME: 'Please enter Portfolio name.',
	ALREADY_EXISTS: 'Portfolio with this name already Exists.',
	GENERIC_ERROR: 'Some Error occured. Please check!',
	SUCCESS: 'Portfolio saved successfully.',
	NOTES_UPDATED: 'Note has been updated.',
	NO_PORTFOLIO:
		'You have no portfolios to track. Create a new portfolio to add transaction and track potential investments',
	MAX_PORTFOLIO: 'User can not add more than 20 portfolios',
	TRANSACTIONS_PARTIALY_SAVED: 'Transactions updated partialy',
	TRANSACTIONS_ERROR: 'No Transaction saved. Some Error Occured!',
	REQUIRED_FIELD_ERROR: 'Missing required values.',
	INVALID_DATE_ERROR: 'Invalid Date: Please choose a date on or before today.',
};

export const BACK_TO_LANDING_LINK = 'Back to Landing page';
export const CREATE_PORTFOLIO_BUTTON = '+  Create New Portfolio';
export const CREATE_PORTFOLIO = 'Create New Portfolio';
export const PORTFOLIO_ACTIONS = {
	SAVE: 'save',
	CANCEL: 'cancel',
	EDIT_NOTES: 'Edit Notes',
	DELETE_PORTFOLIO: 'Delete Portfolio',
	EDIT_PORTFOLIO: 'Edit Portfolio',
	VIEW_PORTFOLIO: 'View Portfolio',
	HELP_GLOSSARY: 'Help Glossary',
	PRINT_PORTFOLIO: 'Print Portfolio',
};

export const PORTFOLIO_TRACKER_KEYS = {
	NAME: 'name',
	DESCRIPTION: 'description',
	ID: 'id',
	INSERTION_DATE: 'insertDate',
	GAIN: 'gain',
	VALUE: 'value',
	CHG_TODAY: 'chgToday',
	PCT_CHG_TODAY: 'pctChgToday',
	DELETE: 'delete',
	EDIT_PORTFOLIO: 'editPortfolio',
	TOTALS: 'Totals',
	PERCENT_PORTFOLIO: 'pctPortfolio',
	RETURN: 'return',
	PRINT: 'print',
};

export const PORTFOLIO_TRACKER_HEADER = [
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: 'Name',
		sortDir: 'asc',
		key: PORTFOLIO_TRACKER_KEYS.NAME,
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: 'Current Value',
		sortDir: 'desc',
		key: PORTFOLIO_TRACKER_KEYS.VALUE,
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: 'Gain/Loss Since Invested',
		sortDir: 'desc',
		key: PORTFOLIO_TRACKER_KEYS.GAIN,
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: '$ Chg',
		sortDir: 'desc',
		key: PORTFOLIO_TRACKER_KEYS.CHG_TODAY,
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: '% Chg',
		sortDir: 'desc',
		key: PORTFOLIO_TRACKER_KEYS.PCT_CHG_TODAY,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		isGrey: true,
		label: 'Notes',
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		isGrey: true,
		label: 'Edit',
	},
];

export const PORTFOLIO_TRACKER_TABLE_DATA_MAPPING = [
	{
		key: PORTFOLIO_TRACKER_KEYS.NAME,
		type: TYPES_OF_ELEMENTS.LINK_LABEL,
		isBold: true,
		label: 'Name',
		isCalculated: true,
		action: PORTFOLIO_ACTIONS.VIEW_PORTFOLIO,
		ellipsisEnabled: true,
		showInSingleRow: true,
		masterAction: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.VALUE,
		type: TYPES_OF_ELEMENTS.NUMBER,
		isBold: true,
		label: 'Current Value',
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.GAIN,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		isBold: true,
		label: 'Gain/Loss Since Invested',
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.CHG_TODAY,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		isBold: true,
		label: '$ Chg',
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.PCT_CHG_TODAY,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		isBold: true,
		label: '% Chg',
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.DESCRIPTION,
		type: TYPES_OF_ELEMENTS.NOTES_ICON,
		label: 'Notes',
		isCalculated: true,
		action: PORTFOLIO_ACTIONS.EDIT_NOTES,
		className: 'click-icon',
		width: 24,
		height: 23,
		showInSingleRow: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.EDIT_PORTFOLIO,
		type: TYPES_OF_ELEMENTS.EDIT_ICON,
		label: 'Edit',
		isCalculated: true,
		action: PORTFOLIO_ACTIONS.EDIT_PORTFOLIO,
		className: 'click-icon',
		width: 21,
		height: 21,
		showInSingleRow: true,
		masterAction: true,
	},
];

export const PORTFOLIO_TABLE_LAST_ROW = [
	{
		key: PORTFOLIO_TRACKER_KEYS.TOTALS,
		type: TYPES_OF_ELEMENTS.LABEL,
		isBold: true,
		isGrey: true,
		noValue: true,
		label: PORTFOLIO_TRACKER_KEYS.TOTALS,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.VALUE,
		type: TYPES_OF_ELEMENTS.NUMBER,
		isBold: true,
		label: 'Current Value',
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.GAIN,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		isBold: true,
		label: 'Gain/Loss Since Invested',
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.CHG_TODAY,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		isBold: true,
		label: '$ Chg',
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.PCT_CHG_TODAY,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		isBold: true,
		label: '% Chg',
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.DESCRIPTION,
		type: '',
		isMobileHide: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.EDIT_PORTFOLIO,
		type: '',
		isMobileHide: true,
	},
];

export type ERROR_MESSAGE_STATE_TYPE = {
	message: string;
	status: MessageBoxStateType;
};

export const PORTFOLIO_TRACKER_TAB_NAMES: { [key: string]: string } = {
	Quotes: 'Quotes',
	Performance: 'Performance',
	Fundamentals: 'Fundamentals',
};

export const TRANSACTIONS_KEYS = {
	NAME: 'name',
	SYMBOL: 'symbol',
	XID: 'xid',
	DESCRIPTION: 'description',
	ID: 'id',
	HOLDING_ID: 'holdingId',
	FEES: 'fees',
	SHARES: 'shares',
	DATE_TIME: 'dateTime',
	UNIT_VALUE: 'unitValue',
	QUANTITY: 'quantity',
	CASH_ACCOUNT_ID: 'cashAccountId',
	DELETE: 'delete',
	TRANSACTIONS: 'transactions',
};

export const ADD_TRANSACTIONS_TABLE_HEADER = [
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		isBold: true,
		label: 'Name',
		isMobile: true,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		isBold: true,
		label: 'Symbol',
		isMobile: true,
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		isBold: true,
		label: 'Date Purchased',
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		isBold: true,
		label: '*Price',
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		isBold: true,
		label: isMobile ? '# of Shares' : 'Number of Shares',
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		isBold: true,
		label: 'Fees',
	},
	{
		type: TYPES_OF_ELEMENTS.LABEL,
		isBold: true,
		label: 'Delete',
		isMobile: true,
	},
];

export const ADD_TRANSACTIONS_TABLE_DATA_MAPPING = [
	{
		key: TRANSACTIONS_KEYS.NAME,
		type: TYPES_OF_ELEMENTS.SYMBOl_DETAILS_LABEL,
		isBold: true,
		isParent: true,
		isLink: true,
		ellipsisEnabled: true,
		showInSingleRow: true,
	},
	{
		key: TRANSACTIONS_KEYS.SYMBOL,
		type: TYPES_OF_ELEMENTS.LABEL,
		isParent: true,
		showInSingleRow: true,
	},
	{
		key: TRANSACTIONS_KEYS.DATE_TIME,
		type: TYPES_OF_ELEMENTS.INPUT,
		typeParam: 'date',
		isInput: true,
		isDate: true,
	},
	{
		key: TRANSACTIONS_KEYS.UNIT_VALUE,
		type: TYPES_OF_ELEMENTS.INPUT,
		placeholder: '0.00',
		isInput: true,
		typeParam: 'number',
		min: '0',
	},
	{
		key: TRANSACTIONS_KEYS.QUANTITY,
		type: TYPES_OF_ELEMENTS.INPUT,
		typeParam: 'number',
		isInput: true,
	},
	{
		key: TRANSACTIONS_KEYS.FEES,
		type: TYPES_OF_ELEMENTS.INPUT,
		typeParam: 'number',
		min: '0',
		placeholder: '0',
		isInput: true,
	},
	{
		key: TRANSACTIONS_KEYS.DELETE,
		type: TYPES_OF_ELEMENTS.TRASH_ICON,
		isButton: true,
		width: 19,
		height: 23,
		showInSingleRow: true,
	},
];

export const PORTFOLIO_TRACKER_ACTION_ITEMS = [
	{
		key: PORTFOLIO_TRACKER_KEYS.PRINT,
		type: TYPES_OF_ELEMENTS.PRINT_ICON,
		action: PORTFOLIO_ACTIONS.PRINT_PORTFOLIO,
		isCalculated: true,
		className: 'click-icon',
		width: 24,
		height: 23,
		text: 'Print',
		color: '#000000',
		icon: PrinterIcon,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.DESCRIPTION,
		type: TYPES_OF_ELEMENTS.NOTES_ICON,
		action: PORTFOLIO_ACTIONS.EDIT_NOTES,
		isCalculated: true,
		className: 'click-icon',
		width: 24,
		height: 23,
		text: 'Notes',
		color: '#000000',
		icon: NotesIcon,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.EDIT_PORTFOLIO,
		type: TYPES_OF_ELEMENTS.EDIT_ICON,
		action: PORTFOLIO_ACTIONS.EDIT_PORTFOLIO,
		isCalculated: true,
		className: 'click-icon',
		width: 21,
		height: 21,
		text: 'Edit',
		masterAction: true,
		color: '#000000',
		icon: EditIcon,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.DELETE,
		type: TYPES_OF_ELEMENTS.DELETE_ICON,
		action: PORTFOLIO_ACTIONS.DELETE_PORTFOLIO,
		isCalculated: true,
		className: 'click-icon',
		width: 19,
		height: 23,
		text: 'Delete',
		color: '#000000',
		icon: DeleteIcon,
	},
];
