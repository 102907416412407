import React, { useEffect, useState } from 'react';
import './news-container.scss';
import HeadlineNews from '../../../welcome-page/headline-news/headline-news';
import { getNews } from '../../../../services/news-data-request-methods';
import {
	buildNewsInputs,
	getChartXLabelwithET,
	prepareNewsData,
} from '../news-utils';
import {
	Heading,
	Button,
	LabelLink,
	Loader,
	SingleSelect,
	Tabs,
	Container,
} from '../../../../@core-ui';
import {
	getHtmlElementFromShadowRoot,
	handleEnterKeyPress,
	scrollIntoView,
	scrollToTop,
	setStateOnDropdownChange,
} from '../../../../utilities/utils';
import { DataRequest } from '../../../../services';
import URLS from '../../../../constants/urlConstants';
import {
	IndicatorText,
	NEWS_CORRELATE_XIDS,
	News_Correlate,
	News_Time_Period,
	TIME_PERIOD_MAPPING,
	TIME_PERIOD_MAPPING_INTERVAL,
	defaultDate,
	headLineNews,
	searchNewsByCategory,
	NEWS_CATEGORY_DROPDOWN,
	NEWS_CORRELATE_DROPDOWN,
	NEWS_TYPE_DROPDOWN,
	TIME_PERIOD_DROPDOWN,
	News_Map,
	News_Type,
	Controls,
	News_Category,
} from '../news-map-constants';
import RenderNews from '../../../news/render-news/render-news';
import NewsByCategory from '../../../news/news-tab/news-by-category/news-by-category';
import { QUOTE_NEWS_CATEGORY } from '../../../news/news-tab/news-tab-constants';
import {
	convertUtcDttmToET,
	getConvertedDate,
} from '../../../../utilities/date-time-formatter-tz';
import { DATE_FORMATS } from '../../../../utilities/date-time-formatter';
import NewsChartContainer from './news-chart-container';
import { RC_WC_MAP } from '../../../../constants/wcConstants';
import { isRealTimeQuote } from '../../../../services/quotes-data-request-methods';

interface NewsContainerProps {
	selectedType: string;
	selectedCategory: string;
	timeFrame: string;
	selectedExchange: string;
	setOptions: (value: any) => void;
	isMobile: any;
}

interface Option {
	type: string;
	category: string;
	duration: string;
	correlate: string;
}

const NewsContainer: React.FC<NewsContainerProps> = ({
	selectedType,
	selectedCategory,
	timeFrame,
	selectedExchange,
	setOptions,
	isMobile,
}) => {
	const [controls, setControls] = useState(Controls.Hide);
	const [newsData, setNewsData] = useState<any>([]);
	const [showNews, setShowNews] = useState<boolean>(false);
	const [newsType, setNewsType] = useState<any>();
	const [newsTypes, setNewsTypes] = useState(News_Type.Economic);
	const [selectedNews, setSelectedNews] = useState<any>();
	const [priceData, setPriceData] = useState<any>([]);
	const [singleSelectOpenedId, setSingleSelectOpenedId] = useState('');
	const [show, setShow] = useState(true);
	const [newsCorrelate, setNewsCorrelate] = useState(News_Correlate.NYSE);
	const [timePeriod, setTimePeriod] = useState(
		isMobile ? News_Time_Period.Month : News_Time_Period.Day,
	);
	const [newsCategory, setNewsCategory] = useState(News_Category.All);
	const [activeSelectedTab, setActiveSelectedTab] = useState(
		QUOTE_NEWS_CATEGORY.Headline_News,
	);
	const [ready, setReady] = useState<boolean>(false);
	const [loadingDisplayText, setLoadingDisplayText] = useState<string>();

	const chartInputs: any = {
		exchange: selectedExchange,
		timeFrame: timeFrame,
	};

	useEffect(() => {
		setNewsCategory(News_Category.All);
	}, [newsTypes]);

	useEffect(() => {
		const element = getHtmlElementFromShadowRoot(
			RC_WC_MAP.QuotesNews.name,
			'.news-map-mobile',
		);

		!isMobile ? scrollToTop() : scrollIntoView(element as HTMLElement);
	}, [selectedNews]);

	useEffect(() => {
		setReady(false);
		if (selectedExchange && selectedType) {
			const getPrice =
				timeFrame === News_Time_Period.Day ? get_1DayPrice : getPriceData;
			Promise.allSettled([getNewsData(), getPrice(selectedExchange)]).then(
				() => {
					setReady(true);
				},
			);
		}
		setShowNews(false);
	}, [selectedType, timeFrame, selectedCategory, selectedExchange]);

	const getNewsData = async () => {
		setReady(false);
		setLoadingDisplayText('');

		const newsInputs = buildNewsInputs(
			selectedType,
			timeFrame,
			selectedCategory,
		);

		setTimeout(() => {
			setLoadingDisplayText('Loading... Please wait.');
		}, 3000);

		const response = await getNews(newsInputs);
		const newsArray = response?.data?.data?.items;
		if (newsArray && newsArray.length > 0) {
			setNewsData([...prepareNewsData(newsArray)]);
		} else {
			setNewsData([]);
		}
	};

	const getPriceData = async (exchange: string) => {
		const now = new Date();
		const start = new Date(
			new Date(now).setDate(now.getDate() - TIME_PERIOD_MAPPING[timeFrame] + 1),
		).toISOString();
		setReady(false);
		const response: any = await DataRequest.execute(
			URLS.PLATFORM.QUOTE_HISTORICAL + `${NEWS_CORRELATE_XIDS[exchange]}`,
			{
				params: {
					startDate: start,
					interval: TIME_PERIOD_MAPPING_INTERVAL[timeFrame],
				},
			},
			{
				realTime: await isRealTimeQuote()
			}
		);

		if (response?.data?.data?.timeseries) {
			const responseData = response.data.data.timeseries;
			setPriceData(responseData);
		} else {
			setPriceData([]);
		}
	};

	const get_1DayPrice = async (exchange: string) => {
		setReady(false);
		const response: any = await DataRequest.execute(
			URLS.PLATFORM.QUOTE_INTRADAY_HISTORICAL +
				`${NEWS_CORRELATE_XIDS[exchange]}`,
			{
				params: {
					duration: 1,
					interval: TIME_PERIOD_MAPPING_INTERVAL['1 Day'],
				},
			},
			{
				realtime: await isRealTimeQuote()
			}
		);
		if (response?.data?.data?.timeseries) {
			const responseData = response.data.data.timeseries;
			setPriceData(responseData);
		} else {
			setPriceData([]);
		}
	};

	const handleColumnClick = (event: Highcharts.PointClickEventObject) => {
		const e = event?.point || event?.target;
		setNewsType({
			category: getChartXLabelwithET(e.category, timeFrame),
			storyCount: e.y,
		});

		let tempNews = [];

		const timeFormat = DATE_FORMATS.HOUR_MINUTE_FORMAT.replace('a', ' a');

		if (timeFrame === News_Time_Period.Day) {
			const currentTime = getConvertedDate(`${defaultDate} ${e.category}`, {
				onlyDate: true,
			});
			const hourTime = currentTime.add(1, 'hour');

			tempNews = newsData.filter(
				(ele: { [x: string]: string | number | Date }) => {
					const labelTime = getConvertedDate(
						`${defaultDate} ${convertUtcDttmToET(ele['documentDateTime'], {
							timeOnly: true,
							timeFormat: timeFormat,
						})}`,
						{ onlyDate: true },
					);

					return labelTime >= currentTime && labelTime <= hourTime;
				},
			);
		} else {
			tempNews = newsData.filter(
				(ele: { [x: string]: string | number | Date }) =>
					convertUtcDttmToET(ele['documentDateTime'], {
						format: 'MMM D',
					}) === e.category,
			);
		}

		tempNews?.forEach((news: any) => {
			news.timestamp = convertUtcDttmToET(news.documentDateTime, {
				format: DATE_FORMATS.ET_VARIANT_DATE_TIME_FORMAT,
			});
		});

		setSelectedNews(tempNews);
		setShowNews(true);
	};

	const revertHandler = () => {
		setActiveSelectedTab(QUOTE_NEWS_CATEGORY.Headline_News);
		setShowNews(false);
	};

	const bindDropDown = (
		selectedValue: string,
		dropdownData: Array<{
			key: any;
			value: string;
		}>,
		handler: any,
		id: string,
	) => {
		return (
			<SingleSelect
				selectedValue={selectedValue}
				data={dropdownData}
				changeHandler={(e) => {
					return setStateOnDropdownChange(e, handler);
				}}
				dataTestId={id}
				setSingleSelectOpenedId={setSingleSelectOpenedId}
				isListOpen={singleSelectOpenedId === id}
				id={id}
			/>
		);
	};

	const handleUpdate = () => {
		const newOptions: Option = {
			type: newsTypes,
			category: newsCategory,
			duration: timePeriod,
			correlate: newsCorrelate,
		};
		setOptions(newOptions);
	};

	const getHandler = () => {
		return show ? hideHandler : showHandler;
	};

	const hideHandler = () => {
		setShow(false);
		setControls(Controls.Show);
	};

	const showHandler = () => {
		setShow(true);
		setControls(Controls.Hide);
	};

	const handleLabelLink = () => {
		return (
			<LabelLink
				text={controls}
				className={'labelLink'}
				onClick={getHandler()}
				onKeyDown={(e) => handleEnterKeyPress(e, getHandler())}
			></LabelLink>
		);
	};

	const getLabelLink = () => {
		return (
			<div
				className={`${show ? 'hide-label' : 'show-label'} ${'control-label'}`}
			>
				{handleLabelLink()}
			</div>
		);
	};

	return (
		<div className="news-container" data-testid="news-container">
			<Container
				applyPadding={!isMobile}
				applyBorder={!isMobile}
				applyWhiteBackground
			>
				{showNews ? (
					<div className="selected-news-container">
						<div className="news-label">
							<Heading
								content={
									selectedType +
									' - ' +
									(selectedCategory === 'Select All' ? 'All' : selectedCategory)
								}
								type={'h2'}
							/>
							<LabelLink
								text={'Revert to Headline News'}
								className={'labelLink'}
								onClick={revertHandler}
								onKeyDown={(e) => handleEnterKeyPress(e, revertHandler)}
							></LabelLink>
						</div>
						<div className="story-count" id="news-stories">
							<Heading
								content={
									newsType.storyCount +
									` ${newsType.storyCount < 2 ? 'Story' : 'Stories '} for ` +
									newsType.category
								}
								type={'h5'}
							/>
						</div>
						<div className="news-stories">
							<RenderNews newsData={selectedNews} />
						</div>
					</div>
				) : (
					<div className="headline-news-component">
						<Tabs
							ariaLabel={activeSelectedTab}
							activeTab={activeSelectedTab}
							onClick={(k: any) => setActiveSelectedTab(k)}
						>
							<div
								key={QUOTE_NEWS_CATEGORY.Headline_News}
								data-label={headLineNews}
							>
								<HeadlineNews hideHeader={true} />
							</div>
							<div
								key={QUOTE_NEWS_CATEGORY.Search_News_By_Category}
								data-label={searchNewsByCategory}
							>
								<NewsByCategory />
							</div>
						</Tabs>
						<div className="component-divider"></div>
					</div>
				)}
			</Container>
			<Container
				applyPadding={!isMobile}
				applyBorder={!isMobile}
				applyWhiteBackground
			>
				{isMobile && getLabelLink()}
				<div className={`${'dropdown-container'} ${!show && 'container-row'}`}>
					<div className="dropdown-row">
						<div className="component-title">
							<Heading content={News_Map} type={'h2'} />
						</div>
						{show && (
							<div className="dropdowns">
								<div className="type-dropdown">
									<div className="type-heading">
										<Heading content={'News'} type={'h6'} />
									</div>
									{bindDropDown(
										newsTypes,
										NEWS_TYPE_DROPDOWN,
										setNewsTypes,
										'drpNewsType',
									)}
								</div>
								<div className="category-dropdown">
									{bindDropDown(
										newsCategory,
										NEWS_CATEGORY_DROPDOWN[newsTypes],
										setNewsCategory,
										'drpNewsCategory',
									)}
								</div>
								<div className="period-dropdown">
									{bindDropDown(
										timePeriod,
										TIME_PERIOD_DROPDOWN,
										setTimePeriod,
										'drpTimePeriod',
									)}
								</div>
							</div>
						)}
						<div className="hide-label">
							<LabelLink
								text={controls}
								className={'labelLink'}
								testId={show ? 'hide-controls' : 'show-controls'}
								onClick={getHandler()}
								onKeyDown={(e) => handleEnterKeyPress(e, getHandler())}
							></LabelLink>
						</div>
					</div>
					<div
						className={`${'dropdown-row second-row'} ${!show && 'show-label'}`}
					>
						{show && (
							<div className="dropdowns second-row-dropdowns">
								<div className="correlate-dropdown">
									<div className="type-heading">
										<Heading content={'Correlate'} type={'h6'} />
									</div>
									{bindDropDown(
										newsCorrelate,
										NEWS_CORRELATE_DROPDOWN,
										setNewsCorrelate,
										'drpCorrelateDropdown',
									)}
								</div>
								<div className="update-button">
									<Button onClick={handleUpdate} testId={'update-chart'}>
										Update Chart
									</Button>
								</div>
							</div>
						)}
						<div className="hide-label">{handleLabelLink()}</div>
					</div>
				</div>
				<div className="indicators-mobile">
					<div className="indicator-text-mobile">
						Bar chart represents number of stories.
					</div>
					<div className="indicator-text-mobile">
						Select color bar to view stories above.
					</div>
					<div className="indicator-pill-mobile">
						<div className="icon-1"></div>
						<div className="icon-label">1 to 5</div>
						<div className="icon-2"></div>
						<div className="icon-label">6 to 15</div>
						<div className="icon-3"></div>
						<div className="icon-label">16 to 25</div>
						<div className="icon-4"></div>
						<div className="icon-label last-label">25+</div>
					</div>
				</div>
				{ready ? (
					<NewsChartContainer
						newsData={newsData}
						priceData={priceData}
						clickHandler={handleColumnClick}
						chartInputs={chartInputs}
					/>
				) : (
					<div className="loader" data-testid="loader">
						<Loader displayText={loadingDisplayText} />
					</div>
				)}
				<div className="indicators">
					<div className="indicator-text">{IndicatorText}</div>
					<div className="indicator-pill">
						<div className="icon-1"></div>
						<div className="icon-label">1 to 5</div>
						<div className="icon-2"></div>
						<div className="icon-label">6 to 15</div>
						<div className="icon-3"></div>
						<div className="icon-label">16 to 25</div>
						<div className="icon-4"></div>
						<div className="icon-label last-label">25+</div>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default NewsContainer;
