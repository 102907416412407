/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import {
	TODAYSPERFORMERS_HEADER,
	MARKETERS,
	TODAYSPERFORMERS_COLUMN,
} from './todays-performers-constants';
import './todays-performers.scss';
import {
	DataRequest,
	getUserPreferences,
	setUserPreferences,
} from '../../../services';
import URLS from '../../../constants/urlConstants';
import {
	Heading,
	NumberLabel,
	Label,
	Loader,
	SingleSelect,
	SymbolDetails,
	Table,
} from '../../../@core-ui';
import NoData from '../../../shared/NoData/NoData';
import { RC_WC_MAP } from '../../../constants/wcConstants';
import {
	getDeviceType,
	getHtmlElementFromShadowRoot,
	delayedMessage,
	sortByKey,
} from '../../../utilities/utils';
import {
	E_DeviceType,
	OP_USER_SETTINGS,
	SORTING_DIRECTION,
	DEFAULT_SORTING_INFO,
} from '../../../constants/appConstants';
import { ISortingInfo } from '../../../@core-ui/Table/table';
import { ITodaysPerformers } from '../../../types/interfaces/ICommon';
import { isRealTimeQuote } from '../../../services/quotes-data-request-methods';

interface TodayProps {
	showHeading: boolean;
}
interface IPreferanceDetails {
	rankingSet: string;
	rankingType: string;
	sortedColumn: string;
	sortDir: string;
}

const TodaysPerformers: React.FC<TodayProps> = (props) => {
	const { showHeading = true } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [performersData, setPerformersData] = useState<any>(null);
	const [selectedValue, setSelectedValue] = useState<IPreferanceDetails>();
	const isMobileView = getDeviceType() === E_DeviceType.Mobile;
	const [sortingInfo, setSortingInfo] = useState<ISortingInfo>();
	const [tableResult, setTableResult] = useState<ITodaysPerformers[]>([]);
	const [filteredData, setFilteredData] = useState<ITodaysPerformers[]>([]);

	useEffect(() => {
		setIsLoading(true);
		selectedValue && Object.keys(selectedValue).length > 0 && makeRakingCall();
	}, [selectedValue]);

	useEffect(() => {
		adjustContainerHeight();
	});

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		setIsLoading(true);
		const preferenceResponse = await getUserPreferences(
			OP_USER_SETTINGS.OP_USER_TODAYS_PERFORMERS_SELECTION,
		);
		let newValues: IPreferanceDetails | null = null;
		if (preferenceResponse?.data?.data?.value) {
			const preferenceValue = JSON.parse(preferenceResponse?.data?.data?.value);
			let rankingObj: any = null;
			let sortObj: any = DEFAULT_SORTING_INFO;
			const valuesArr = preferenceValue.split('|');
			if (valuesArr && valuesArr.length > 1) {
				rankingObj = {
					[MARKETERS.RANKING_SET.key]: valuesArr[0],
					[MARKETERS.RANKING_TYPE.key]: valuesArr[1],
				};
			} else {
				const data = JSON.parse(preferenceValue);
				rankingObj = {
					[MARKETERS.RANKING_SET.key]: data[MARKETERS.RANKING_SET.key],
					[MARKETERS.RANKING_TYPE.key]: data[MARKETERS.RANKING_TYPE.key],
				};
				sortObj = {
					sortedColumn: data?.sortedColumn || selectedValue?.sortedColumn,
					sortDir: data?.sortDir || selectedValue?.sortDir,
				};
			}
			if (rankingObj) {
				newValues = {
					...rankingObj,
					...sortObj,
				};
				setSortingInfo({
					...sortObj,
				});
				setSelectedValue(newValues as IPreferanceDetails);
			}
		}
		if (newValues === null) {
			setSelectedValue({
				rankingSet: MARKETERS.RANKING_SET.dropDownData[0].key,
				rankingType: MARKETERS.RANKING_TYPE.dropDownData[0].key,
				...DEFAULT_SORTING_INFO,
			});
			setSortingInfo(DEFAULT_SORTING_INFO);
		}
	};
	const setEmptyState = () => {
		setIsLoading(false);
		setPerformersData(null);
	};

	const adjustContainerHeight = () => {
		const parentHeight = document.getElementsByTagName(
			'md-todays-performers',
		)?.[0]?.parentElement?.clientHeight;
		const childContainer = getHtmlElementFromShadowRoot(
			RC_WC_MAP.TodaysPerformers.name,
			'tbody',
		) as HTMLElement;
		const dropDownList = getHtmlElementFromShadowRoot(
			RC_WC_MAP.TodaysPerformers.name,
			'.dropDownList ul',
		) as HTMLElement;
		if (parentHeight) {
			if (childContainer) {
				//Adjust table content div as per view
				childContainer.style.maxHeight = `${
					parentHeight - (isMobileView ? 157 : 139)
				}px`;
			}
			if (dropDownList) {
				dropDownList.style.maxHeight = `${
					parentHeight - (isMobileView ? 138 : 96)
				}px`;
			}
		}
	};

	const makeRakingCall = async () => {
		const response: any = await DataRequest.execute(
			URLS.PLATFORM.MARKET_RANKINGS,
			{
				params: {
					[MARKETERS.RANKING_SET.key]:
						selectedValue?.[
							MARKETERS.RANKING_SET.key as keyof IPreferanceDetails
						],
					[MARKETERS.RANKING_TYPE.key]:
						selectedValue?.[
							MARKETERS.RANKING_TYPE.key as keyof IPreferanceDetails
						],
					count: 20,
				},
			},
			{
				realtime: await isRealTimeQuote()
			}
		);

		if (response?.data) {
			const responseData = response.data?.data?.marketRankings || {};
			const data = new Map();
			let venueXids = '';
			responseData?.length > 0 &&
				responseData.forEach((item: any) => {
					data.set(item.venueXid, new Map());
					data.get(item.venueXid).set('change', item.lastTrade?.change);
					data.get(item.venueXid).set('changePct', item.changePercent?.today);
					data.get(item.venueXid).set('venueXid', item.venueXid);
					venueXids += `${item.venueXid},`;
				});
			if (venueXids.length > 0) {
				await getXrefData(venueXids, data);
			} else {
				setEmptyState();
			}
		} else {
			setIsLoading(false);
			setPerformersData(response);
		}
	};

	const getXrefData = async (venueXids: string, data: any) => {
		const outputXref = await DataRequest.execute(
			`${URLS.PLATFORM.XREF_GET_BY_VENUEXID}`,
			{ params: { venueXids: venueXids.substring(0, venueXids.length - 2) } },
		);
		const responseData = outputXref?.data?.data;
		responseData?.items?.forEach((item: any) => {
			const venue: number = item.xids?.venue || -1;
			if (venue > 0 && data.has(venue)) {
				if (!item.name) {
					data.delete(venue);
				} else {
					data.get(venue).set('symbol', item.symbol);
					data.get(venue).set('description', item.name);
				}
			}
		});
		setFilteredPerformanceData(data);
	};

	const changeDropDown = (event: any, id: string) => {
		setDropDownValues(event.getAttribute('data-key'), id);
	};

	const setFilteredPerformanceData = (performersData: any) => {
		performersData.forEach((value: any, key: number) => {
			if (!value.has('description')) {
				performersData.delete(key);
			}
		});
		const data: ITodaysPerformers[] = [];
		performersData.forEach((value: any, key: number) => {
			data.push({
				venueXid: key,
				name: value.get('description'),
				change: value.get('change'),
				changePercent: value.get('changePct'),
			});
		});
		setPerformersData(performersData);
		setFilteredData(data);
		settingTableResult(
			data,
			selectedValue?.sortedColumn ?? DEFAULT_SORTING_INFO.sortedColumn,
			(selectedValue?.sortDir ??
				DEFAULT_SORTING_INFO.sortDir) as SORTING_DIRECTION,
		);
		setIsLoading(false);
	};

	function setDropDownValues(val: string, key: string) {
		if (
			selectedValue &&
			selectedValue[key as keyof IPreferanceDetails] !== val
		) {
			const newObj = {
				[key]: val,
			};
			const updatedSelectVal = {
				...selectedValue,
				...newObj,
				sortedColumn: sortingInfo?.sortedColumn ?? selectedValue?.sortedColumn,
				sortDir: sortingInfo?.sortDir ?? selectedValue?.sortDir,
			};
			setSelectedValue(updatedSelectVal);
			setUserPreferences(
				OP_USER_SETTINGS.OP_USER_TODAYS_PERFORMERS_SELECTION,
				JSON.stringify(updatedSelectVal),
			);
		}
	}

	function generateColValue(
		item: any,
		text: string,
		className: string,
		isLabel?: boolean,
		isHoverEnabledLabel?: boolean,
		venueXid?: number,
	) {
		return (
			<div className={className}>
				{isLabel ? (
					<React.Fragment>
						{isHoverEnabledLabel ? (
							<SymbolDetails venueXid={venueXid ?? 0} displayName={text} />
						) : (
							<Label
								text={text}
								size={'m'}
								isBold={item.isBold}
								ellipsisEnabled={item.showEllipsis}
							/>
						)}
					</React.Fragment>
				) : (
					<NumberLabel
						value={text}
						isBold={item.isBold}
						numberConfig={{
							showColor: item.showColor,
							preFix: item.preFix,
							postFixValue: item.postFix,
						}}
					/>
				)}
			</div>
		);
	}

	function sortDataHandler(colName: string, sortDirection: SORTING_DIRECTION) {
		setSortingInfo({
			sortedColumn: colName,
			sortDir: sortDirection,
		});
		const userPreferenceDetail = {
			...selectedValue,
			sortedColumn: colName,
			sortDir: sortDirection,
		};
		settingTableResult(filteredData, colName, sortDirection);
		setUserPreferences(
			OP_USER_SETTINGS.OP_USER_TODAYS_PERFORMERS_SELECTION,
			JSON.stringify(userPreferenceDetail),
		);
	}
	const settingTableResult = (
		data: ITodaysPerformers[],
		colName: string,
		dir: SORTING_DIRECTION,
	) => {
		const sortingData = [...data]?.sort(sortByKey(colName, dir));
		const content: ITodaysPerformers[] = [];
		sortingData.forEach((item: any) => {
			const dataObj = {} as ITodaysPerformers;
			dataObj.name = generateColValue(
				{ showEllipsis: true },
				item?.name,
				'companyName',
				true,
				true,
				item?.venueXid,
			);
			dataObj.venueXid = item.venueXid;
			dataObj.change = generateColValue(
				{ showColor: true, preFix: true, isBold: true },
				item?.change,
				'numberValue',
			);
			dataObj.changePercent = generateColValue(
				{ showColor: true, preFix: true, isBold: true },
				item?.changePercent,
				'numberValue',
			);
			content.push(dataObj);
		});
		setTableResult(content);
	};

	const getPerformersBody = () => {
		return performersData &&
			performersData['size'] > 0 &&
			tableResult &&
			sortingInfo ? (
			<div className={'performersContentMain'}>
				<Table
					data={tableResult}
					columns={TODAYSPERFORMERS_COLUMN}
					showVerticalBorder={false}
					showHorizontalBorder={true}
					isSortable={true}
					sortingInfo={sortingInfo}
					sortDataHandler={sortDataHandler}
				/>
			</div>
		) : (
			<div className={'no-data-container'}>
				<NoData error={performersData?.error} />
			</div>
		);
	};

	return (
		<div id="todays-performers-container">
			{showHeading && (
				<header className={'header'}>
					<Heading
						testId={'todays-performers'}
						content={TODAYSPERFORMERS_HEADER}
					/>
					{!isMobileView && (
						<Label text={delayedMessage()} testId={`delay-message`} isGrey />
					)}
				</header>
			)}
			<main className={'performersContainer'}>
				<div className={'performersDropDown'}>
					<div className="dropDownPerformer">
						<SingleSelect
							data={MARKETERS.RANKING_SET.dropDownData}
							changeHandler={changeDropDown}
							id={MARKETERS.RANKING_SET.key}
							selectedValue={
								selectedValue?.[
									MARKETERS.RANKING_SET.key as keyof IPreferanceDetails
								]
							}
							dataTestId={MARKETERS.RANKING_SET.key}
						/>
					</div>
					<div className="dropDownPerformer">
						<SingleSelect
							data={MARKETERS.RANKING_TYPE.dropDownData}
							changeHandler={changeDropDown}
							id={MARKETERS.RANKING_TYPE.key}
							selectedValue={
								selectedValue?.[
									MARKETERS.RANKING_TYPE.key as keyof IPreferanceDetails
								]
							}
							dataTestId={MARKETERS.RANKING_TYPE.key}
						/>
					</div>
				</div>
				{!isLoading ? getPerformersBody() : <Loader />}
				{isMobileView && !isLoading && <Label text={delayedMessage()} isGrey />}
			</main>
		</div>
	);
};

export default TodaysPerformers;
