import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import { IQuoteResponse } from '../types/interfaces';
import { resolvePromiseWithError } from '../utilities/utils';
import { getUserAccess } from './user-access';

export const isRealTimeData = () => {
	let realTimeQuoteInfo: any = sessionStorage.getItem('REAL_TIME_QUOTE_INFO')

	try {
		realTimeQuoteInfo = JSON.parse(realTimeQuoteInfo);
	} catch (error) {
		realTimeQuoteInfo = null;
		console.error(error);
	}

	return realTimeQuoteInfo?.realTimeQuote == true;
}

export const isRealTimeQuote = async () => {
	const userToken = sessionStorage.getItem('MD_ACCESS_TOKEN');
	let realTimeQuoteInfo: any = sessionStorage.getItem('REAL_TIME_QUOTE_INFO')

	try {
		realTimeQuoteInfo = JSON.parse(realTimeQuoteInfo);
	} catch (error) {
		realTimeQuoteInfo = null;
		console.error(error);
	}

	if (!realTimeQuoteInfo || realTimeQuoteInfo.userToken != userToken) {
		const userAccess = await getUserAccess();
		realTimeQuoteInfo = {
			realTimeQuote: userAccess?.data?.data?.realTimeQuote,
			userToken: userToken
		}
		
		sessionStorage.setItem('REAL_TIME_QUOTE_INFO', JSON.stringify(realTimeQuoteInfo))
	}

	return realTimeQuoteInfo?.realTimeQuote == true;
}

export const getQuotes = async (
	venueXids: number[] | string,
	navNotYield = false
): Promise<IQuoteResponse> => {
	if (!venueXids.length) {
		return await resolvePromiseWithError();
	}

	const isRealTime = await isRealTimeQuote();

	return await DataRequest.execute(
		URLS.PLATFORM.QUOTE,
		{
			params: {
				venueXids: venueXids,
				marketHoursTradeType: 'normal',
				clearPremarketQuote: true,
				navNotYield: navNotYield,
			},
		},
		{
			realtime: isRealTime == true,
		},
	);
};
