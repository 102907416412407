import React, { useState, useEffect, useCallback } from 'react';
import {
	Table,
	Button,
	ButtonVariant,
	Separator,
	MessageBox,
	MessageBoxStateType,
	Loader,
	SymbolDetails,
} from '../../../@core-ui';
import {
	WatchlistLimit,
	WatchlistLimitMessage,
	NoWatchlistAddedMessage,
	getClassificationName,
	WatchlistSavedSuccessfullMessage,
} from './customize-watchlist-constants';
import {
	addHoldings,
	addWatchlist,
	getWatchlist,
	getWatchlistHoldings,
	deleteHoldings,
	getXrefDataByXids,
	getUserPreferences,
	setUserPreferences,
} from '../../../services';
import { sortByKey, handleEnterKeyPress } from '../../../utilities/utils';
import { SearchBox } from '../../shared/search';
import { IXrefResponseItem } from '../../../types/interfaces';
import { DeleteIcon } from '../../../assets/Icons';
import './customize-watchlist.scss';
import BackLink from './../../../shared/back-link/back-link';
import {
	OP_USER_SETTINGS,
	SORTING_DIRECTION,
} from '../../../constants/appConstants';
import { ISortingInfo } from '@/@core-ui/Table/table';

export interface IWatchlistHoldingItem {
	name: React.ReactNode;
	symbol: string;
	type: string;
	xid: number;
	id?: number;
	insertDateTime?: string;
}

interface CustomizeWatchlistProps {
	onClose: () => void;
}

const CustomizeWatchlist: React.FC<CustomizeWatchlistProps> = ({ onClose }) => {
	const defaultSortingInfo: ISortingInfo = {
		sortedColumn: 'name',
		sortDir: 'asc',
	};
	const [sortingInfo, setSortingInfo] =
		useState<ISortingInfo>(defaultSortingInfo);
	const [watchlistHoldings, setWatchlistHoldings] = useState<
		IWatchlistHoldingItem[]
	>([]);
	const [prevHoldingsXids, setPrevHoldingsXids] = useState<number[]>([]);
	const [deletedHoldingIds, setDeletedHoldingIds] = useState<number[]>([]);
	const [alertInfo, setAlertInfo] = useState({
		showAlert: false,
		content: WatchlistSavedSuccessfullMessage,
		state: MessageBoxStateType.Success,
	});
	const [loadingState, setLoadingState] = useState({
		isLoading: true,
		isReady: false,
	});

	useEffect(() => {
		fetchData();
	}, []);

	const setInitialState = () => {
		setLoadingState({ isLoading: true, isReady: false });
	};

	const fetchData = async () => {
		setInitialState();
		const watchlistResponse = await getWatchlist();

		// Get watchlist and its holdings, map xref data to holdings
		if (watchlistResponse && watchlistResponse.data?.data?.items?.length > 0) {
			const watchlistId = watchlistResponse.data.data.items[0].id;
			const holdingsResponse = await getWatchlistHoldings(watchlistId);
			if (holdingsResponse && holdingsResponse.data?.data?.items?.length > 0) {
				const holdings = holdingsResponse.data?.data?.items;
				const previousholdingsXids = holdings.map((x: any) => x.xid);
				setPrevHoldingsXids(previousholdingsXids);
				const items = await getAndMapXrefDataToHoldings(
					holdings,
					previousholdingsXids,
				);
				await getSortingPreference(items);
			}
		}

		setLoadingState({ isLoading: false, isReady: true });
	};

	const getAndMapXrefDataToHoldings = async (
		holdings: any[],
		xids: number[],
	): Promise<IWatchlistHoldingItem[]> => {
		const xrefResponse = await getXrefDataByXids(xids);
		const items: IWatchlistHoldingItem[] = [];
		holdings.forEach((holding) => {
			const xrefItem = xrefResponse?.data?.data?.items?.find(
				(xrefData) => xrefData?.xids?.venue === holding?.xid,
			);
			if (xrefItem) {
				items.push({
					name: xrefItem.name,
					symbol: xrefItem.symbol,
					type: getClassificationName(xrefItem.classification?.name),
					xid: holding.xid,
					id: holding.id,
					insertDateTime: holding.insertDateTime,
				});
			} else {
				items.push({
					name: '--',
					symbol: holding?.description ?? '--',
					type: '--',
					xid: holding?.xid,
					id: holding?.id,
					insertDateTime: holding?.insertDateTime,
				});
			}
		});
		return items;
	};

	const getSortingPreference = async (
		watchlistHoldingItems: IWatchlistHoldingItem[],
	) => {
		const sortingPreferenceResponse = await getUserPreferences(
			OP_USER_SETTINGS.OP_USER_CUSTOMIZE_WATCHLIST_SELECTION,
		);
		let sortedPreference;
		if (sortingPreferenceResponse?.data?.data?.value) {
			const preferenceValue = JSON.parse(
				sortingPreferenceResponse?.data?.data?.value,
			);
			sortedPreference = JSON.parse(preferenceValue);
		}
		const savedSorting: ISortingInfo = {
			sortedColumn:
				sortedPreference?.sortedColumn || defaultSortingInfo.sortedColumn,
			sortDir: sortedPreference?.sortDir || defaultSortingInfo.sortDir,
		};
		setSortingInfo(savedSorting);

		const sortedData = [...watchlistHoldingItems]?.sort(
			sortByKey(savedSorting.sortedColumn, savedSorting.sortDir),
		);
		setWatchlistHoldings(sortedData);
	};

	const saveHoldings = async (watchlistId: string) => {
		const newHoldings = watchlistHoldings.filter(
			(item) => !prevHoldingsXids.includes(item.xid),
		);

		if (newHoldings.length === 0) {
			return;
		}

		const holdings = newHoldings.map((item) => ({
			xid: item.xid,
			description: item.symbol,
		}));

		await addHoldings(watchlistId, holdings);
	};

	const handleSubmit = async () => {
		setInitialState();
		await handleWatchlist();
		setAlertInfo({
			content: WatchlistSavedSuccessfullMessage,
			state: MessageBoxStateType.Success,
			showAlert: true,
		});
		setTimeout(() => {
			redirectToLandingPage();
		}, 500);
	};

	const handleWatchlist = async () => {
		let watchlistId = '';

		const { data } = await getWatchlist();

		if (data?.data?.items?.length) {
			watchlistId = data.data.items[0].id;
		} else {
			const { data } = await addWatchlist();
			watchlistId = data?.data?.id;
		}

		if (watchlistId) {
			const saveHoldingsPromise = saveHoldings(watchlistId);
			const deleteHoldingsPromise =
				deletedHoldingIds.length > 0
					? deleteHoldings(watchlistId, deletedHoldingIds.join(','))
					: Promise.resolve();

			await Promise.all([saveHoldingsPromise, deleteHoldingsPromise]);
			setLoadingState({ isLoading: false, isReady: true });
		}
	};

	const handleAddWatchlistItem = useCallback(
		(item: IXrefResponseItem) => {
			const symbolExists = watchlistHoldings.some(
				(watchlistItem) => watchlistItem.symbol === item.symbol,
			);

			if (symbolExists) {
				setAlertInfo({
					showAlert: true,
					content: `${item.name} has already been added!`,
					state: MessageBoxStateType.Fail,
				});
				return;
			}

			const { name, symbol, xids, classification } = item;
			const holding: IWatchlistHoldingItem = {
				name,
				symbol,
				type: getClassificationName(classification.name),
				xid: xids.venue,
			};
			const updatedHoldings = [holding, ...watchlistHoldings];
			const sortedHoldings = [...updatedHoldings]?.sort(
				sortByKey(
					sortingInfo?.sortedColumn ?? defaultSortingInfo.sortedColumn,
					sortingInfo?.sortDir ?? defaultSortingInfo.sortDir,
				),
			);
			setWatchlistHoldings(sortedHoldings);
		},
		[watchlistHoldings],
	);

	const onDeleteWatchlistItem = useCallback(
		(deletedHoldingId: number, deletedHoldingXid: number) => {
			setDeletedHoldingIds([...deletedHoldingIds, deletedHoldingId]);

			let updatedWatchlistItems = [];

			// deleting newly added holding based on XID as holding id not available in this case
			if (!deletedHoldingId && deletedHoldingXid) {
				updatedWatchlistItems = watchlistHoldings.filter(
					(item) => item.xid !== deletedHoldingXid,
				);
			} else {
				updatedWatchlistItems = watchlistHoldings.filter(
					(item) => item.id !== deletedHoldingId,
				);
			}
			setWatchlistHoldings(updatedWatchlistItems);
		},
		[watchlistHoldings, deletedHoldingIds],
	);

	const redirectToLandingPage = () => {
		onClose?.();
	};

	const onAlertCloseClick = () => {
		setAlertInfo({ ...alertInfo, showAlert: false });
	};

	const getTableColHTML = (item: any) => {
		return (
			<button
				className="delete-button"
				onClick={() =>
					onDeleteWatchlistItem(item.id as number, item.xid as number)
				}
				onKeyDown={(e) => {
					handleEnterKeyPress(e, () =>
						onDeleteWatchlistItem(item.id as number, item.xid as number),
					);
				}}
				data-testid="delete-holding"
			>
				<DeleteIcon />
			</button>
		);
	};

	const getSymbolAccessor = (item: any) => {
		return (
			<SymbolDetails
				venueXid={item.xid}
				displayName={item.name}
				isCompanyNameOrSymbol={true}
				isClickable={item.name != '--'}
			/>
		);
	};

	const processData = (item: any) => {
		const indicesObj = {} as IWatchlistHoldingItem;
		indicesObj.id = item.id;
		indicesObj.xid = item.xid;
		indicesObj.insertDateTime = item.insertDateTime;
		indicesObj.name = item.name;
		indicesObj.symbol = item.symbol;
		indicesObj.type = item.type;
		return indicesObj;
	};

	const settingTableResult = (
		data: IWatchlistHoldingItem[],
		colName: string,
		dir: SORTING_DIRECTION,
	) => {
		const sortingData = [...data].sort(sortByKey(colName, dir));
		const content: IWatchlistHoldingItem[] = [];
		sortingData.forEach((item) => {
			content.push(processData(item));
		});
		setWatchlistHoldings(content);
	};

	function sortDataHandler(colName: string, sortDirection: SORTING_DIRECTION) {
		const sortedInfo = {
			sortedColumn: colName,
			sortDir: sortDirection,
		};
		setSortingInfo(sortedInfo);
		settingTableResult(watchlistHoldings, colName, sortDirection);
		setUserPreferences(
			OP_USER_SETTINGS.OP_USER_CUSTOMIZE_WATCHLIST_SELECTION,
			JSON.stringify(sortedInfo),
		);
	}

	const renderTable = () => {
		if (watchlistHoldings.length === 0 && !loadingState.isLoading) {
			return <MessageBox content={NoWatchlistAddedMessage} />;
		} else if (loadingState.isLoading) {
			return <Loader size="small" />;
		}

		return (
			<Table
				data-testid="testCustomizeWatchlistTable"
				data={watchlistHoldings}
				showHorizontalBorder={true}
				pageSize={WatchlistLimit}
				columns={[
					{
						header: 'Name',
						accessor: getSymbolAccessor,
						sortDir: 'asc',
						key: 'name',
					},
					{
						header: 'Symbol',
						accessor: 'symbol',
						sortDir: 'asc',
						key: 'symbol',
					},
					{ header: 'Type', accessor: 'type', sortDir: 'asc', key: 'type' },
					{
						header: 'Delete',
						accessor: getTableColHTML,
						key: 'delete',
					},
				]}
				isSortable={true}
				sortingInfo={sortingInfo}
				sortDataHandler={sortDataHandler}
			/>
		);
	};

	return (
		<div
			className="customize-watchlist-view"
			data-testid={'customize-watchlist-view'}
		>
			<div className="customize-watchlist-container">
				{alertInfo.showAlert && (
					<MessageBox
						content={alertInfo.content}
						state={alertInfo.state}
						enableCloseIcon={true}
						onClose={onAlertCloseClick}
						autoHide={true}
					/>
				)}
				<BackLink onClick={redirectToLandingPage} />
				<div
					className="customize-watchlist-content"
					data-watchlist-name="customize-watchlist-content-data"
				>
					<div className="customize-watchlist-header">Customize Watchlist</div>
					<Separator />
					{watchlistHoldings.length >= WatchlistLimit ? (
						<div className="watchlist-limit-message">
							{WatchlistLimitMessage}
						</div>
					) : (
						!loadingState.isLoading && (
							<div className="search-container">
								<div className="add-security-header">Add a Security</div>
								<SearchBox
									onAddSymbol={handleAddWatchlistItem}
									placeholder="Enter Name or Symbol"
								/>
							</div>
						)
					)}
					<div
						className={`customize-watchlist-table-container ${
							loadingState.isLoading ? 'loading' : ''
						}`}
						data-testid="customize-watchlist-table"
					>
						{renderTable()}
					</div>
					<div className="customize-watchlist-count-container">
						{watchlistHoldings.length} of {WatchlistLimit} Possible Securities
					</div>
					<div className="button-container">
						<Button
							onClick={redirectToLandingPage}
							variant={ButtonVariant.Secondary}
							disabled={loadingState.isLoading}
							testId="cancel-submit"
						>
							Cancel
						</Button>
						<Button
							onClick={handleSubmit}
							disabled={loadingState.isLoading}
							testId="save-submit"
						>
							Save
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomizeWatchlist;
