import { IXrefResponseItem } from '../../../../types/interfaces';
import {
	OP_USER_SETTINGS,
	TYPES_OF_ELEMENTS,
} from '../../../../constants/appConstants';
import { PORTFOLIO_TRACKER_KEYS } from '../portfolio-constants';
import { ISortingInfo } from '../../../../@core-ui/Table/table';

export interface ITableTransaction {
	[key: string]: any;
	id: string;
	insertDateTime: string;
	description?: string;
	quantity: number;
	last?: number | string;
	todaysDollarChange: number | string;
	todaysPercentChange: number | string;
	currentDollarValue?: number | string;
	percentOfPortfolio?: number | string;
	gainLossSincePurchase?: number | string;
	totalReturn?: number | string;
	oneMonthPercentPerf: number | string;
	threeMonthPercentPerf: number | string;
	sixMonthPercentPerf: number | string;
	twelveMonthPercentPerf: number | string;
}

export interface ITableHolding extends ITableTransaction {
	[key: string]: any;
	xid: number;
	entity?: number;
	transactions?: ITableTransaction[];
	xrefData?: IXrefResponseItem;
	name: JSX.Element;
	symbol: string;
	epsTtm?: number | string;
	peRatio?: number | string;
	divExpiryDate?: string;
	divPayDate?: string;
	divYield?: number | string;
	fiftyTwoWeekLowDollar: number | string;
	fiftyTwoWeekHighDollar: number | string;
	marketCapitalTNA: number | string;
	hasRecentNews: boolean;
}

export interface IHoldingsWithTransactions {
	[key: string]: any;
	name: string;
	id: string;
	holdings: ITableHolding[];
	combinedTotals?: string;
	combinedCurrentDollarValue?: number | string;
	combinedGainLossSincePurchase?: number | string;
	combinedTotalReturn?: number | string;
}
export interface IAllocationItem {
	[key: string]: any;
}
export interface IAssetAllocation {
	colors: string[];
	data: number[];
	allocations: IAllocationItem[];
}

export interface IPortfolioSortingInfo {
	Quotes?: ISortingInfo;
	Performance?: ISortingInfo;
	Fundamentals?: ISortingInfo;
	[key: string]: ISortingInfo | undefined;
}

export const PORTFOLIO_TRAKER_KEYS = {
	NAME: 'name',
	// Quotes Data
	LAST_PRICE: 'last',
	TODAY_CHANGE: 'todaysDollarChange',
	TODAY_PERCENT_CHANGE: 'todaysPercentChange',
	SHARES_OWNED: 'quantity',
	CURRENT_VALUE: 'currentDollarValue',
	PERCENT_PORTFOLIO: 'percentOfPortfolio',
	GAIN_LOSS_SINCE_PURCHASE: 'gainLossSincePurchase',
	TOTAL_RETURN: 'totalReturn',
	COMBINED_TOTALS: 'combinedTotals',
	// Combined Data
	COMBINED_CURRENT_DOLLAR_VALUE: 'combinedCurrentDollarValue',
	COMBINED_GAIN_LOSS_SINCE_PURCHASE: 'combinedGainLossSincePurchase',
	COMBINED_TOTAL_RETURN: 'combinedTotalReturn',
	// Performance Data
	ONE_MONTH_PERCENT_PERF: 'oneMonthPercentPerf',
	THREE_MONTH_PERCENT_PERF: 'threeMonthPercentPerf',
	SIX_MONTH_PERCENT_PERF: 'sixMonthPercentPerf',
	TWELVE_MONTH_PERCENT_PERF: 'twelveMonthPercentPerf',
	// Fundamentals Data
	EPS_TTM: 'epsTtm',
	PE_RATIO: 'peRatio',
	DIV_EXPIRY_DATE: 'divExpiryDate',
	DIV_PAY_DATE: 'divPayDate',
	DIV_YIELD: 'divYield',
	FIFTY_TWO_WEEK_LOW_DOLLAR: 'fiftyTwoWeekLowDollar',
	FIFTY_TWO_WEEK_HIGH_DOLLAR: 'fiftyTwoWeekHighDollar',
	MARKET_CAPITAL_TNA: 'marketCapitalTNA',
	HASRECENTNEWS: 'hasRecentNews',
};

type TableHeader = {
	type: string;
	isGrey?: boolean;
	label?: string;
	isBold?: boolean;
	key?: string;
	preFix?: boolean;
	showColor?: boolean;
	showPercent?: boolean;
	commaSeparated?: boolean;
	sortDir?: string;
};

export interface ITableConstant {
	header: TableHeader[];
	mapping: any[];
	lastRow?: TableHeader[];
}

// Quotes
export const QUOTES_PORTFOLIO_TRACKER_HEADER: TableHeader[] = [
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: 'Name',
		key: PORTFOLIO_TRAKER_KEYS.NAME,
		sortDir: 'asc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: 'Last Price',
		key: PORTFOLIO_TRAKER_KEYS.LAST_PRICE,
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: `Today's $ Change`,
		key: PORTFOLIO_TRAKER_KEYS.TODAY_CHANGE,
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: 'Shares Owned',
		key: PORTFOLIO_TRAKER_KEYS.SHARES_OWNED,
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: 'Current $ Value',
		key: PORTFOLIO_TRAKER_KEYS.CURRENT_VALUE,
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: '% of Portfolio',
		key: PORTFOLIO_TRAKER_KEYS.PERCENT_PORTFOLIO,
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: '$ Gain/Loss Since Purchase',
		commaSeparated: true,
		key: PORTFOLIO_TRAKER_KEYS.GAIN_LOSS_SINCE_PURCHASE,
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		key: PORTFOLIO_TRAKER_KEYS.TOTAL_RETURN,
		commaSeparated: true,
		label: 'Total Return',
		sortDir: 'desc',
	},
];

export const QUOTES_PORTFOLIO_TRACKER_TABLE_DATA_MAPPING = [
	{
		key: PORTFOLIO_TRAKER_KEYS.NAME,
		type: TYPES_OF_ELEMENTS.LINK_LABEL,
		isBold: true,
		isCalculated: true,
		ellipsisEnabled: true,
		isExpanded: false,
		hasTransactions: false,
		hasRecentNews: false,
		isHideTitle: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.LAST_PRICE,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.TODAY_CHANGE,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.SHARES_OWNED,
		type: TYPES_OF_ELEMENTS.NUMBER,
		commaSeparated: true,
		precision: 0,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.CURRENT_VALUE,
		type: TYPES_OF_ELEMENTS.NUMBER,
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.PERCENT_PORTFOLIO,
		type: TYPES_OF_ELEMENTS.NUMBER,
		showPercent: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.GAIN_LOSS_SINCE_PURCHASE,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		commaSeparated: true,
		showColor: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.TOTAL_RETURN,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		commaSeparated: true,
		showPercent: true,
	},
];

export const QUOTES_PORTFOLIO_TABLE_LAST_ROW: TableHeader[] = [
	{
		key: PORTFOLIO_TRAKER_KEYS.COMBINED_TOTALS,
		type: TYPES_OF_ELEMENTS.LABEL,
		isBold: true,
		isGrey: true,
		label: 'Portfolio Totals',
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.LAST_PRICE,
		type: '',
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.TODAY_CHANGE,
		type: '',
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.SHARES_OWNED,
		type: '',
	},

	{
		key: PORTFOLIO_TRAKER_KEYS.COMBINED_CURRENT_DOLLAR_VALUE,
		type: TYPES_OF_ELEMENTS.NUMBER,
		commaSeparated: true,
		label: 'Current $ Value',
		isBold: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.PERCENT_PORTFOLIO,
		type: '',
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.COMBINED_GAIN_LOSS_SINCE_PURCHASE,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		label: '$ Gain/Loss',
		showColor: true,
		isBold: true,
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.COMBINED_TOTAL_RETURN,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		label: 'Total Return',
		commaSeparated: true,
		showPercent: true,
	},
];

export const QuotesPortfolioConstants: ITableConstant = {
	header: QUOTES_PORTFOLIO_TRACKER_HEADER,
	mapping: QUOTES_PORTFOLIO_TRACKER_TABLE_DATA_MAPPING,
	lastRow: QUOTES_PORTFOLIO_TABLE_LAST_ROW,
};

// Performance
export const PERFORMANCE_PORTFOLIO_TRACKER_HEADER: TableHeader[] = [
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: 'Name',
		key: PORTFOLIO_TRAKER_KEYS.NAME,
		sortDir: 'asc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		key: PORTFOLIO_TRAKER_KEYS.SHARES_OWNED,
		label: 'Shares Owned',
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: 'Last Price',
		key: PORTFOLIO_TRAKER_KEYS.LAST_PRICE,
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		key: PORTFOLIO_TRAKER_KEYS.TODAY_CHANGE,
		label: "Today's $ Change",
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: "Today's % Change",
		key: PORTFOLIO_TRAKER_KEYS.TODAY_PERCENT_CHANGE,
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		key: PORTFOLIO_TRAKER_KEYS.ONE_MONTH_PERCENT_PERF,
		label: '1 Month % Perf',
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		key: PORTFOLIO_TRAKER_KEYS.THREE_MONTH_PERCENT_PERF,
		label: '3 Month % Perf',
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		key: PORTFOLIO_TRAKER_KEYS.SIX_MONTH_PERCENT_PERF,
		label: '6 Month % Perf',
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		key: PORTFOLIO_TRAKER_KEYS.TWELVE_MONTH_PERCENT_PERF,
		label: '12 Month % Perf',
		sortDir: 'desc',
	},
];

export const PERFORMANCE_PORTFOLIO_TRACKER_TABLE_DATA_MAPPING = [
	{
		key: PORTFOLIO_TRAKER_KEYS.NAME,
		type: TYPES_OF_ELEMENTS.LINK_LABEL,
		isCalculated: true,
		ellipsisEnabled: true,
		isExpanded: false,
		isBold: true,
		hasTransactions: false,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.SHARES_OWNED,
		type: TYPES_OF_ELEMENTS.NUMBER,
		precision: 0,
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.LAST_PRICE,
		commaSeparated: true,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.TODAY_CHANGE,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.TODAY_PERCENT_CHANGE,
		type: TYPES_OF_ELEMENTS.NUMBER,
		showPercent: true,
		preFix: true,
		showColor: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.ONE_MONTH_PERCENT_PERF,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.THREE_MONTH_PERCENT_PERF,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.SIX_MONTH_PERCENT_PERF,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.TWELVE_MONTH_PERCENT_PERF,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		commaSeparated: true,
	},
];

export const PerformancePortfolioConstants: ITableConstant = {
	header: PERFORMANCE_PORTFOLIO_TRACKER_HEADER,
	mapping: PERFORMANCE_PORTFOLIO_TRACKER_TABLE_DATA_MAPPING,
};

// Fundamentals
export const FUNDAMNETALS_PORTFOLIO_TRACKER_HEADER: TableHeader[] = [
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		key: PORTFOLIO_TRAKER_KEYS.NAME,
		label: 'Name',
		sortDir: 'asc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		key: PORTFOLIO_TRAKER_KEYS.EPS_TTM,
		label: 'EPS(TTM)',
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		key: PORTFOLIO_TRAKER_KEYS.PE_RATIO,
		label: 'P/E Ratio',
		sortDir: 'desc',
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.DIV_EXPIRY_DATE,
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		label: 'Div. Ex-Date',
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		key: PORTFOLIO_TRAKER_KEYS.DIV_PAY_DATE,
		isGrey: true,
		label: 'Div. Pay Date',
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		key: PORTFOLIO_TRAKER_KEYS.DIV_YIELD,
		isGrey: true,
		label: 'Div. Yield',
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		key: PORTFOLIO_TRAKER_KEYS.FIFTY_TWO_WEEK_LOW_DOLLAR,
		isGrey: true,
		label: '52 week Low($)',
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		key: PORTFOLIO_TRAKER_KEYS.LAST_PRICE,
		isGrey: true,
		label: 'Last',
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		isGrey: true,
		key: PORTFOLIO_TRAKER_KEYS.FIFTY_TWO_WEEK_HIGH_DOLLAR,
		label: '52 Week High($)',
		sortDir: 'desc',
	},
	{
		type: TYPES_OF_ELEMENTS.HEADER,
		key: PORTFOLIO_TRAKER_KEYS.MARKET_CAPITAL_TNA,
		isGrey: true,
		label: 'Market Cap/TNA',
		sortDir: 'desc',
	},
];

export const FUNDAMNETALS_PORTFOLIO_TRACKER_TABLE_DATA_MAPPING = [
	{
		key: PORTFOLIO_TRAKER_KEYS.NAME,
		type: TYPES_OF_ELEMENTS.LINK_LABEL,
		isBold: true,
		isCalculated: true,
		ellipsisEnabled: true,
		isExpanded: false,
		hasTransactions: false,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.EPS_TTM,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.PE_RATIO,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.DIV_EXPIRY_DATE,
		type: TYPES_OF_ELEMENTS.LABEL,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.DIV_PAY_DATE,
		type: TYPES_OF_ELEMENTS.LABEL,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.DIV_YIELD,
		type: TYPES_OF_ELEMENTS.NUMBER,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.FIFTY_TWO_WEEK_LOW_DOLLAR,
		type: TYPES_OF_ELEMENTS.NUMBER,
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.LAST_PRICE,
		type: TYPES_OF_ELEMENTS.NUMBER,
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.FIFTY_TWO_WEEK_HIGH_DOLLAR,
		type: TYPES_OF_ELEMENTS.NUMBER,
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRAKER_KEYS.MARKET_CAPITAL_TNA,
		type: TYPES_OF_ELEMENTS.NUMBER,
		internationalCurrency: true,
	},
];

export const FundamentalsPortfolioConstants: ITableConstant = {
	header: FUNDAMNETALS_PORTFOLIO_TRACKER_HEADER,
	mapping: FUNDAMNETALS_PORTFOLIO_TRACKER_TABLE_DATA_MAPPING,
};

export const PortfolioTrackerMessage = {
	NoDataExist: `<span>There are no transactions in this portfolio.<br>To add a transaction select “Edit” and search by Name or Symbol.</span>`,
	GenericError: 'Some Error occured. Please check!',
};

export const HOVER_TABLE_HEADER = [
	{
		label: 'Name',
		type: TYPES_OF_ELEMENTS.LABEL,
		isGrey: true,
	},
	{
		label: '% Portfolio',
		type: TYPES_OF_ELEMENTS.LABEL,
		isGrey: true,
	},
	{
		label: '$ Gain/loss',
		type: TYPES_OF_ELEMENTS.LABEL,
		isGrey: true,
	},
	{
		label: 'Return',
		type: TYPES_OF_ELEMENTS.LABEL,
		isGrey: true,
	},
];

export const HOVER_TABLE_DATA_MAPPING = [
	{
		key: PORTFOLIO_TRACKER_KEYS.NAME,
		type: TYPES_OF_ELEMENTS.LABEL,
		isGrey: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.PERCENT_PORTFOLIO,
		type: TYPES_OF_ELEMENTS.NUMBER,
		isBold: true,
		postFix: '%',
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.GAIN,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		isBold: true,
		commaSeparated: true,
	},
	{
		key: PORTFOLIO_TRACKER_KEYS.RETURN,
		type: TYPES_OF_ELEMENTS.NUMBER,
		preFix: true,
		showColor: true,
		isBold: true,
		commaSeparated: true,
		postFix: '%',
	},
];

export const STOCKS_TYPE = {
	LARGE_CAP_STOCKS: 'Large Cap Stocks',
	MID_CAP_STOCKS: 'Mid Cap Stocks',
	SMALL_CAP_STOCKS: 'Small Cap Stocks',
	MUTUAL_FUNDS: 'Mutual Funds',
	ETFS: 'ETFs',
	GOVERNMENT: 'Government',
	CORPORATE: 'Corporate',
	MUNICIPAL: 'Municipal',
	CASH: 'Cash',
	OTHER: 'Other',
};

export const STOCKS_TYPE_COLOR_MAPPING = [
	{
		key: STOCKS_TYPE.LARGE_CAP_STOCKS,
		color: '#002856',
		isEnabled: true,
	},
	{
		key: STOCKS_TYPE.MID_CAP_STOCKS,
		color: '#778692',
		isEnabled: true,
	},
	{
		key: STOCKS_TYPE.SMALL_CAP_STOCKS,
		color: '#0070AF',
		isEnabled: true,
	},
	{
		key: STOCKS_TYPE.MUTUAL_FUNDS,
		color: '#333333',
	},
	{
		key: STOCKS_TYPE.ETFS,
		color: '#80B7D7',
	},
	{
		key: STOCKS_TYPE.GOVERNMENT,
		color: '#C68B88',
	},
	{
		key: STOCKS_TYPE.CORPORATE,
		color: '#801323',
	},
	{
		key: STOCKS_TYPE.MUNICIPAL,
		color: '#DEDEB4',
	},
	{
		key: STOCKS_TYPE.CASH,
		color: '#168459',
	},
	{
		key: STOCKS_TYPE.OTHER,
		color: '#A7C2A4',
	},
];

export enum LegendControls {
	Show = 'Show Legend',
	Hide = 'Hide Legend',
}

export const PORTFOLIO_TRACKER_PREFERENCE_KEY: { [key: string]: string } = {
	Quotes: OP_USER_SETTINGS.OP_USER_PORTFOLIO_QUOTES_TAB_SELECTION,
	Performance: OP_USER_SETTINGS.OP_USER_PORTFOLIO_PERFORMANCE_TAB_SELECTION,
	Fundamentals: OP_USER_SETTINGS.OP_USER_PORTFOLIO_FUNDAMENTALS_TAB_SELECTION,
};

export const DEFAULT_PORTFOLIO_TRACKER_SORTING_INFO: {
	[key: string]: ISortingInfo;
} = {
	Quotes: {
		sortedColumn: PORTFOLIO_TRAKER_KEYS.TOTAL_RETURN,
		sortDir: 'desc',
	},
	Performance: {
		sortedColumn: PORTFOLIO_TRAKER_KEYS.TWELVE_MONTH_PERCENT_PERF,
		sortDir: 'desc',
	},
	Fundamentals: {
		sortedColumn: PORTFOLIO_TRAKER_KEYS.LAST_PRICE,
		sortDir: 'desc',
	},
};
