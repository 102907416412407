/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import IndicesChart from './indices/indices-chart';
import IndicesTable from './indices/indices-table';
import Tabs from '../../@core-ui/Tabs/tabs';
import './markets-indices.scss';
import {
	INDICES_NAME,
	INDICES_KEY_VALUE_DATA,
	INDICES_NAMES,
	MARKET_INDICES_TAB,
} from './markets-indices-constants';
import { IIndices, IIndiceDetail, IQuote } from '../../types/interfaces';
import {
	AMERICAN_INDICES,
	WORLD_INDICES,
	E_DeviceType,
	OP_USER_SETTINGS,
	DEFAULT_SORTING_INFO,
	SORTING_DIRECTION,
} from '../../constants/appConstants';
import {
	Label,
	LabelLink,
	Loader,
	NumberLabel,
	SingleSelect,
	Collapsible,
	Container,
} from '../../@core-ui';
import { formatNumber } from '../../utilities/formatter';
import { DATE_FORMATS } from '../../utilities/date-time-formatter';
import NoData from '../../shared/NoData/NoData';
import IndicesCharts from './indices-charts/indices-charts';
import {
	handleEnterKeyPress,
	getDeviceType,
	delayedMessage,
	setSessionStorage,
	getSessionStorageItem,
	sortByKey,
} from '../../utilities/utils';
import Statistics from '../quotes-news/statistics/statistics';
import TodaysPerformers from '../welcome-page/todays-performers/todays-performers';
import { convertUtcDttmToET } from '../../utilities/date-time-formatter-tz';
import {
	getQuotes,
	getUserPreferences,
	setUserPreferences,
} from '../../services';
import { useQuotesNewsContext } from '../quotes-news/quotes-news-container/quotes-news-container';
import { ISortingInfo } from '../../@core-ui/Table/table';
interface MarketsIndicesProps {
	isRefreshClicked?: boolean;
	setIsRefreshClicked?: (isRefreshClicked: boolean) => void;
}

interface IPreferanceDetails {
	venueXid: number;
	sortedColumn: string;
	sortDir: SORTING_DIRECTION;
}

const MarketsIndices: React.FC<MarketsIndicesProps> = ({
	isRefreshClicked = false,
	setIsRefreshClicked,
}) => {
	const { showWorldIndicesTab, setShowWorldIndicesTab } =
		useQuotesNewsContext();
	const activeTabBeforeRefresh =
		isRefreshClicked && getSessionStorageItem(MARKET_INDICES_TAB);
	const [isLoading, setIsLoading] = useState(true);
	const [activeSelectedTab, setActiveSelectedTab] = useState(
		showWorldIndicesTab
			? INDICES_NAME[1]
			: activeTabBeforeRefresh || INDICES_NAME[0],
	);
	const [selectedValue, setSelectedValue] = useState(
		showWorldIndicesTab
			? INDICES_KEY_VALUE_DATA[1].key
			: activeTabBeforeRefresh || INDICES_KEY_VALUE_DATA[0].key,
	);
	const [preferenceKey, setPreferenceKey] = useState(
		isRefreshClicked &&
			getSessionStorageItem(MARKET_INDICES_TAB) == INDICES_KEY_VALUE_DATA[1].key
			? OP_USER_SETTINGS.OP_USER_INDICES_MARKET_WORLD_INDICES_SELECTION
			: OP_USER_SETTINGS.OP_USER_INDICES_MARKET_AMERICAN_INDICES_SELECTION,
	);
	const [selectedIndicesXid, setSelectedIndicesXid] = useState<number>();
	const [tableResult, setTableResult] = useState<IIndices[]>([]);
	const [quotes, setQuotes] = useState<IIndiceDetail[]>([]);
	const [marketsData, setMarketsData] = useState<any>(null);
	const [statisticsCurrentDate, setStatisticsCurrentDate] = useState<string>();

	const [sortingInfo, setSortingInfo] = useState<ISortingInfo>();

	const [preferencesDetails, setPreferencesDetails] =
		useState<IPreferanceDetails>();
	const device = getDeviceType();
	const isMobileView = device === E_DeviceType.Mobile;

	useEffect(() => {
		fetchData(preferenceKey);
	}, [activeSelectedTab, selectedValue]);

	useEffect(() => {
		if (showWorldIndicesTab) {
			!isMobileView && onActiveTabClick(INDICES_NAME[1]);
			isMobileView && changeDropDown(INDICES_NAME[1]);
		}
	}, [showWorldIndicesTab]);

	useEffect(() => {
		if (isRefreshClicked) {
			setActiveSelectedTab(
				getSessionStorageItem(MARKET_INDICES_TAB) ?? INDICES_NAME[0],
			);
			setIsRefreshClicked?.(false);
		} else {
			setSessionStorage(MARKET_INDICES_TAB, INDICES_NAME[0]);
		}

		!showWorldIndicesTab && fetchData();
	}, []);

	const fetchData = async (pKey?: string): Promise<any> => {
		const preferenceResponse = await getUserPreferences(pKey ?? preferenceKey);
		if (preferenceResponse?.data?.data?.value?.includes('venueXid')) {
			const preferenceValue = JSON.parse(preferenceResponse.data?.data?.value);
			if (preferenceValue) {
				const parsePreference = JSON.parse(preferenceValue);
				setSelectedIndicesXid(parsePreference.venueXid);
				if (parsePreference?.sortedColumn && parsePreference.sortDir) {
					setPreferencesDetails({
						venueXid: parsePreference.venueXid,
						sortedColumn: parsePreference.sortedColumn,
						sortDir: parsePreference.sortDir,
					});
					setSortingInfo({
						sortedColumn: parsePreference.sortedColumn,
						sortDir: parsePreference.sortDir,
					});
					tableData({
						sortedColumn: parsePreference.sortedColumn,
						sortDir: parsePreference.sortDir,
					});
				} else {
					setSortingInfo(DEFAULT_SORTING_INFO);
					setPreferencesDetails({
						venueXid: parsePreference.venueXid,
						...DEFAULT_SORTING_INFO,
					});
					tableData(DEFAULT_SORTING_INFO);
				}
			}
		} else {
			setSortingInfo(DEFAULT_SORTING_INFO);
			setDefaultIndices();
			tableData(DEFAULT_SORTING_INFO);
		}
	};

	const setDefaultIndices = async () => {
		let indicesList: { key: any }[] = [];
		if (!isMobileView) {
			indicesList =
				activeSelectedTab == INDICES_NAME[0] ? AMERICAN_INDICES : WORLD_INDICES;
		} else {
			indicesList =
				selectedValue == INDICES_KEY_VALUE_DATA[0].key
					? AMERICAN_INDICES
					: WORLD_INDICES;
		}
		onSymbolClick(indicesList[0]?.key, DEFAULT_SORTING_INFO);
	};

	const onSymbolClick = (venueXid: number, sortingInfo: ISortingInfo) => {
		setSelectedIndicesXid(venueXid);
		const userPreference = {
			venueXid: venueXid,
			sortedColumn:
				sortingInfo?.sortedColumn || preferencesDetails?.sortedColumn,
			sortDir: sortingInfo?.sortDir || preferencesDetails?.sortDir,
		};
		setPreferencesDetails(userPreference as IPreferanceDetails);
		setUserPreferences(preferenceKey, JSON.stringify(userPreference));
	};

	const onActiveTabClick = (k: string) => {
		setShowWorldIndicesTab?.(false);
		if (k === INDICES_NAME[0]) {
			setPreferenceKey(
				OP_USER_SETTINGS.OP_USER_INDICES_MARKET_AMERICAN_INDICES_SELECTION,
			);
		} else if (k === INDICES_NAME[1]) {
			setPreferenceKey(
				OP_USER_SETTINGS.OP_USER_INDICES_MARKET_WORLD_INDICES_SELECTION,
			);
		}
		setActiveSelectedTab(k);
		setSessionStorage(MARKET_INDICES_TAB, k);
	};

	const tableData = async (sortingInfo: ISortingInfo) => {
		let indicesList = [];
		if (!isMobileView) {
			indicesList =
				activeSelectedTab == INDICES_NAME[0] ? AMERICAN_INDICES : WORLD_INDICES;
		} else {
			indicesList =
				selectedValue == INDICES_KEY_VALUE_DATA[0].key
					? AMERICAN_INDICES
					: WORLD_INDICES;
		}
		const venueXids = indicesList.map((a) => a.key).toString();
		const response = await getQuotes(venueXids);
		const quoteContent = response?.data?.data?.quotes;

		if (quoteContent) {
			setStatisticsCurrentDate(
				convertUtcDttmToET(quoteContent[0]?.data?.lastTrade?.date, {
					format: DATE_FORMATS.MONTH_DATE_YEAR_FORMAT,
				}),
			);
			const quotesData: IIndiceDetail[] = [];
			indicesList.forEach((item) => {
				const indicesData = quoteContent.find(
					(quote: any) => quote.data.venueXid == item.key,
				) as IQuote;
				quotesData.push({
					venueXid: indicesData?.data?.venueXid,
					name: item.value,
					last: indicesData?.data?.lastTrade?.last,
					change: indicesData?.data?.lastTrade?.change,
					changePercent: indicesData?.data?.changePercent?.today,
					volume: indicesData?.data?.volume?.average10Day,
					dateTime: indicesData?.data?.lastTrade?.date,
					delayedMinutes: indicesData?.data?.quoteDelayMinutes,
				});
			});
			setQuotes(quotesData);
			settingTableResult(
				quotesData,
				sortingInfo?.sortedColumn,
				sortingInfo?.sortDir,
			);

			setMarketsData(quoteContent);
		} else {
			setMarketsData(response);
		}
		setIsLoading(false);
	};

	const processData = (item: any, colName: string, dir: SORTING_DIRECTION) => {
		const indicesObj = {} as IIndices;
		indicesObj.name = (
			<LabelLink
				text={item?.name as string}
				onClick={() =>
					onSymbolClick(item.venueXid, {
						sortedColumn: colName,
						sortDir: dir,
					})
				}
				onKeyDown={(e) =>
					handleEnterKeyPress(e, () =>
						onSymbolClick(item.venueXid, {
							sortedColumn: colName,
							sortDir: dir,
						}),
					)
				}
			></LabelLink>
		);
		indicesObj.venueXid = item.venueXid;
		indicesObj.last = (
			<Label text={formatNumber(item?.last as number)?.value} />
		);
		indicesObj.change = (
			<NumberLabel
				value={formatNumber(item?.change as number)?.value}
				numberConfig={{
					showColor: true,
					preFix: true,
				}}
			/>
		);
		indicesObj.changePercent = (
			<NumberLabel
				value={formatNumber(item?.changePercent as number).value}
				numberConfig={{
					showColor: true,
					preFix: true,
				}}
			/>
		);
		return indicesObj;
	};

	const settingTableResult = (
		data: IIndices[],
		colName: string,
		dir: SORTING_DIRECTION,
	) => {
		const sortingData = [...data].sort(sortByKey(colName, dir));
		const content: IIndices[] = [];
		sortingData.forEach((item) => {
			content.push(processData(item, colName, dir));
		});
		setTableResult(content);
	};

	function sortDataHandler(colName: string, sortDirection: SORTING_DIRECTION) {
		setSortingInfo({
			sortedColumn: colName,
			sortDir: sortDirection,
		});
		const userPreferenceDetail = {
			venueXid: selectedIndicesXid ?? preferencesDetails?.venueXid,
			sortedColumn: colName,
			sortDir: sortDirection,
		};
		setPreferencesDetails(userPreferenceDetail as IPreferanceDetails);
		settingTableResult(quotes, colName, sortDirection);
		setUserPreferences(preferenceKey, JSON.stringify(userPreferenceDetail));
	}

	const changeDropDown = (k: string) => {
		let pKey;
		setShowWorldIndicesTab?.(false);
		if (k === INDICES_NAME[0]) {
			setPreferenceKey(
				OP_USER_SETTINGS.OP_USER_INDICES_MARKET_AMERICAN_INDICES_SELECTION,
			);
			pKey = OP_USER_SETTINGS.OP_USER_INDICES_MARKET_AMERICAN_INDICES_SELECTION;
		} else if (k === INDICES_NAME[1]) {
			setPreferenceKey(
				OP_USER_SETTINGS.OP_USER_INDICES_MARKET_WORLD_INDICES_SELECTION,
			);
			pKey = OP_USER_SETTINGS.OP_USER_INDICES_MARKET_WORLD_INDICES_SELECTION;
		}
		fetchData(pKey);
		setSelectedValue(k);
		setActiveSelectedTab(k);
	};

	const selectedIndices = quotes.find(
		(x) => x?.venueXid === selectedIndicesXid,
	);

	const chartView = () => {
		return (
			<div className="indices-container">
				<div className="chart-container">
					{selectedIndices && (
						<>
							<IndicesChart
								data={selectedIndices}
								enablePreviousClose={true}
								isMobileView={isMobileView}
							/>
							<div className="delayed-market-data">
								<Label
									text={`${convertUtcDttmToET(selectedIndices.dateTime, {
										format: DATE_FORMATS.ET_DATE_TIME_FORMAT,
									})} ${delayedMessage(selectedIndices.delayedMinutes)}`}
								/>
							</div>
						</>
					)}
				</div>
				<div className="indices-table-container">
					{tableResult && sortingInfo && (
						<IndicesTable
							data={tableResult}
							sortingInfo={sortingInfo}
							sortDataHandler={sortDataHandler}
						/>
					)}
				</div>
				{isMobileView && selectedIndices && (
					<div className="delayed-market-data-indices">
						<Label
							text={`${convertUtcDttmToET(selectedIndices.dateTime, {
								format: DATE_FORMATS.ET_DATE_TIME_FORMAT,
							})}`}
						/>
						<div>{delayedMessage(selectedIndices.delayedMinutes)}</div>
					</div>
				)}
			</div>
		);
	};

	const htmlBody = () => {
		return (activeSelectedTab || selectedValue) ===
			INDICES_NAMES.IndicesCharts ? (
			<div>
				<IndicesCharts />
			</div>
		) : (
			chartView()
		);
	};

	const getDesktopBody = () => {
		return (
			<React.Fragment>
				<div className="tab-container">
					<Tabs
						id={'quote-new-header-tab'}
						ariaLabel={activeSelectedTab}
						activeTab={activeSelectedTab}
						onClick={(k: any) => onActiveTabClick(k)}
					>
						{INDICES_NAME.map((key) => {
							return <div key={key} data-label={key}></div>;
						})}
					</Tabs>
				</div>
				{marketsData && !marketsData.error ? (
					htmlBody()
				) : (
					<div className={'no-data-container'}>
						<NoData error={marketsData?.error} />
					</div>
				)}
			</React.Fragment>
		);
	};

	const getMobileView = () => {
		if (isLoading) return <Loader />;
		return (
			<React.Fragment>
				<div className={'collapsible-section'}>
					<Collapsible title="Indices" open={true}>
						<div className={'newsDropDown'}>
							<SingleSelect
								data={INDICES_KEY_VALUE_DATA}
								changeHandler={(event: any) =>
									changeDropDown(event.getAttribute('data-key'))
								}
								selectedValue={selectedValue}
							/>
						</div>
						{marketsData && !marketsData.error ? (
							htmlBody()
						) : (
							<div className={'no-data-container'}>
								<NoData error={marketsData?.error} />
							</div>
						)}
					</Collapsible>
				</div>
				<div className={'collapsible-section'}>
					<Collapsible title="Today's Performers">
						<TodaysPerformers showHeading={!isMobileView} />
					</Collapsible>
				</div>
				<div className={'collapsible-section'}>
					<Collapsible title={`Statistics for ${statisticsCurrentDate}`}>
						<Statistics />
					</Collapsible>
				</div>
			</React.Fragment>
		);
	};

	const getDesktopView = () => {
		return (
			<Container
				applyPadding
				applyBorder
				applyWhiteBackground
				hasLoader={isLoading}
			>
				{isLoading && <Loader />}
				{!isLoading && getDesktopBody()}
			</Container>
		);
	};

	return (
		<div className="markets-indices">
			{!isMobileView && getDesktopView()}
			{isMobileView && getMobileView()}
		</div>
	);
};

export default MarketsIndices;
