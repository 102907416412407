import React, { useState, useEffect } from 'react';

import {
	Loader,
	Heading,
	Table,
	Label,
	NumberLabel,
	SymbolDetails,
	Collapsible,
	SingleSelect,
} from '../../@core-ui';
import { Container } from '../../@core-ui/Container/container';
import NoData from '../../shared/NoData/NoData';
import { logDebugInfo } from '../../utilities/logger';
import {
	DataRequest,
	getEquitiesCurrentRatios,
	getQuotes,
	getUserPreferences,
	setUserPreferences,
} from '../../services';
import URLS from '../../constants/urlConstants';
import {
	ERROR_MESSAGES,
	E_DeviceType,
	OP_USER_SETTINGS,
	SORTING_DIRECTION,
} from '../../constants/appConstants';
import { getXrefDataBySymbols } from '../../services/xref-data-request-methods';
import './peers.scss';
import {
	delayedMessage,
	getDeviceType,
	sortByKey,
} from '../../utilities/utils';
import { ISortingInfo } from '../../@core-ui/Table/table';

type PeersProps = {
	repno: string;
	symbol: string;
	companyName: string;
};

const Peers: React.FC<PeersProps> = (props: PeersProps) => {
	const [isLoading, setIsLoading] = useState(true);
	const [peersData, setPeersData] = useState<any>({});
	const [tempSelectedSymbol, setTempSelectedSymbol] = useState<any>(null);
	const [sortingInfo, setSortingInfo] = useState<ISortingInfo>();

	const PeersDefaultSortingInfo: ISortingInfo = {
		sortedColumn: 'Perf12M',
		sortDir: 'desc',
	};

	const isMobileView = getDeviceType() === E_DeviceType.Mobile;

	useEffect(() => {
		fetchData(props.repno);
	}, [props.repno, props.symbol]);

	const fetchData = async (repno: any) => {
		await getSortingInfo();
		setPeersData({});
		const peers: any = {};
		setIsLoading(true);

		// add target ticker
		peers[props.symbol] = {
			EpsTTM: '--',
			PERatio: '--',
			Perf3M: '--',
			Perf12M: '--',
			CompanyName: props.companyName,
			Symbol: props.symbol,
		};

		if (repno) {
			const response: any = await DataRequest.execute(URLS.CUSTOM.PEERS, {
				params: {
					repno: repno,
				},
			});

			response?.data?.data?.forEach((item: any) => {
				peers[item.Ticker] = {
					EpsTTM: '--',
					PERatio: '--',
					Perf3M: '--',
					Perf12M: '--',
					CompanyName: item.CompanyName,
					Symbol: item.Ticker,
				};
			});
		}

		await getFundamentalsData(peers);
		setIsLoading(false);
	};

	const getSortingInfo = async () => {
		const preferenceResponse = await getUserPreferences(
			OP_USER_SETTINGS.OP_USER_PEERS_COMPARISON_SELECTION,
		);
		let sortingPrefrences: ISortingInfo = PeersDefaultSortingInfo;
		if (preferenceResponse?.data?.data?.value) {
			const preferenceValue = JSON.parse(preferenceResponse.data?.data?.value);
			if (preferenceValue) {
				const parsePreference = JSON.parse(preferenceValue);
				sortingPrefrences = {
					sortedColumn: parsePreference?.sortedColumn,
					sortDir: parsePreference?.sortDir,
				};
			}
		}
		setSortingInfo(sortingPrefrences);
	};

	const setEntityData = (fundamentalsDataRes: any, peers: any) => {
		fundamentalsDataRes?.map((funDataItem: any) => {
			if (funDataItem && funDataItem?.status == 'fulfilled') {
				const entityXid = funDataItem?.value?.requestInputs?.entityXid; // venueXid == entityXid here

				if (entityXid) {
					Object.keys(peers).forEach((symbol) => {
						if (peers[symbol] && peers[symbol]?.entityXid == entityXid) {
							peers[symbol] = {
								...peers[symbol],
								EpsTTM:
									funDataItem?.value?.data?.data?.ratios?.perShare
										?.epsIncludingExtraordinaryItemsTtm,
								PERatio:
									funDataItem?.value?.data?.data?.ratios?.valuation
										?.peExcludingExtraordinaryItemsTtm,
							};
						}
					});
				}
			}
		});
	};

	const setVenuedata = (quotesDataRes: any, peers: any) => {
		quotesDataRes?.data?.data?.quotes?.map((quoteDataItem: any) => {
			const venueXid = quoteDataItem?.data?.venueXid;
			if (venueXid) {
				Object.keys(peers).forEach((symbol) => {
					if (peers[symbol] && peers[symbol]?.venueXid == venueXid) {
						peers[symbol] = {
							...peers[symbol],
							Perf3M: quoteDataItem?.data?.changePercent?.threeMonth,
							Perf12M: quoteDataItem?.data?.changePercent?.oneYear,
						};
					}
				});
			}
		});
	};

	const getFundamentalsData = async (peers: any) => {
		const xrefData: any = await getXrefDataBySymbols(
			Object.keys(peers).map((symbol: string) => {
				return { symbol };
			}),
		);

		const fundamentalsDataReq: any = [];
		let fundamentalsDataRes: any = null;
		let quotesDataRes: any = null;
		const venueXids: any = [];

		xrefData?.data?.data?.items?.map((xItem: any) => {
			const entityXid = xItem?.xids?.entity;
			const venueXid = xItem?.xids?.venue;

			if (entityXid && venueXid) {
				if (peers[xItem?.symbol]) {
					peers[xItem?.symbol] = {
						...peers[xItem?.symbol],
						entityXid: entityXid,
						venueXid: venueXid,
					};
				}

				venueXids.push(venueXid);
				fundamentalsDataReq.push(getEquitiesCurrentRatios(entityXid));
			} else {
				peers[xItem?.error?.inputData?.symbol].status = 404;
			}
		});

		quotesDataRes = await getQuotes(venueXids);
		fundamentalsDataRes = await Promise.allSettled(fundamentalsDataReq);

		setEntityData(fundamentalsDataRes, peers);
		setVenuedata(quotesDataRes, peers);

		setPeersData({ ...peers });
	};

	const sortPeersData = (sortedColumn: string, sortDir: SORTING_DIRECTION) => {
		const sortedData = Object.values(peersData)?.sort(
			sortByKey(sortedColumn, sortDir),
		);
		return sortedData;
	};

	const sortDataHandler = (
		colName: string,
		sortDirection: SORTING_DIRECTION,
	) => {
		const sortInfo = {
			sortedColumn: colName,
			sortDir: sortDirection,
		};
		const sortedData = sortPeersData(colName, sortDirection);
		setPeersData({ ...sortedData });
		setSortingInfo({
			sortedColumn: colName,
			sortDir: sortDirection,
		});
		setUserPreferences(
			OP_USER_SETTINGS.OP_USER_PEERS_COMPARISON_SELECTION,
			JSON.stringify(sortInfo),
		);
	};

	const removedInvalidSymbols = (peers: any = {}) => {
		const tempPeers = peers;

		Object.keys(peers).forEach((symbol) => {
			if (peers[symbol]?.status == 404) {
				delete tempPeers[symbol];
			}
		});

		return tempPeers;
	};

	const renderMobileHTML = (symbol: any) => {
		const data: any = Object.values(removedInvalidSymbols(peersData));
		const symbolData = data?.find((item: any) => item.Symbol == symbol);

		logDebugInfo(['Symbol', symbol, 'Data', symbolData]);

		if (!symbolData) {
			return <NoData />;
		}

		const tempArray = [
			{
				label: 'EPS (TTM)',
				value: <NumberLabel value={symbolData.EpsTTM} />,
			},
			{
				label: 'P/E Ratio',
				value: (
					<NumberLabel
						value={symbolData.PERatio}
						isBold={true}
						fontType={'neue-bold'}
					/>
				),
			},
			{
				label: '3 Month % Perf',
				value: (
					<NumberLabel
						value={symbolData.Perf3M}
						isBold={true}
						fontType={'neue-bold'}
						numberConfig={{ preFix: true }}
					/>
				),
			},
			{
				label: '12 Month % Perf',
				value: (
					<NumberLabel
						value={symbolData.Perf12M}
						isBold={true}
						fontType={'neue-bold'}
						numberConfig={{ preFix: true }}
					/>
				),
			},
		];

		const columns = [
			{
				header: 'Field',
				accessor: (item: any) => {
					return item.label;
				},
			},
			{
				header: 'Value',
				accessor: (item: any) => {
					return item.value;
				},
			},
		];

		return (
			<>
				<SingleSelect
					data={data.map((item: any) => {
						return {
							key: item.Symbol,
							value: item.CompanyName,
						};
					})}
					changeHandler={(event: any) => {
						setTempSelectedSymbol(event.getAttribute('data-key'));
					}}
				/>
				<Table data={tempArray} columns={columns} hideHeader={true} />
			</>
		);
	};

	const renderHtml = () => {
		if (isLoading) {
			return <Loader />;
		}

		let data: any = Object.values(removedInvalidSymbols(peersData));

		data = sortPeersData(
			sortingInfo?.sortedColumn as string,
			sortingInfo?.sortDir as SORTING_DIRECTION,
		);

		if (data?.length == 0) {
			return <NoData text={ERROR_MESSAGES.DEFAULT_NO_DATA} applyMinHeight />;
		}

		if (isMobileView) {
			return renderMobileHTML(tempSelectedSymbol || data[0]?.Symbol);
		}

		data = data?.map((item: any) => {
			if (item) {
				return {
					CompanyName: (
						<SymbolDetails
							venueXid={item.venueXid}
							displayName={item.CompanyName}
							isCompanyNameOrSymbol={true}
							isClickable
						/>
					),
					EpsTTM: <NumberLabel value={item.EpsTTM} />,
					PERatio: <NumberLabel value={item.PERatio} />,
					Perf3M: (
						<NumberLabel
							value={item.Perf3M}
							isBold={true}
							fontType={'neue-bold'}
							numberConfig={{
								showColor: true,
								showPercent: true,
								preFix: true,
							}}
						/>
					),
					Perf12M: (
						<NumberLabel
							value={item.Perf12M}
							isBold={true}
							fontType={'neue-bold'}
							numberConfig={{
								showColor: true,
								showPercent: true,
								preFix: true,
							}}
						/>
					),
				};
			}
		});

		const columns = [
			{
				header: 'Name',
				accessor: 'CompanyName',
				isHtmlContent: true,
				sortDir: 'asc',
				key: 'CompanyName',
				isLabel: true,
			},
			{
				header: `EPS
				(TTM)`,
				accessor: 'EpsTTM',
				isHtmlContent: true,
				sortDir: 'desc',
				key: 'EpsTTM',
			},
			{
				header: `P/E
				Ratio`,
				accessor: 'PERatio',
				isHtmlContent: true,
				sortDir: 'desc',
				key: 'PERatio',
			},
			{
				header: `3 Month
				% Perf`,
				accessor: 'Perf3M',
				isHtmlContent: true,
				sortDir: 'desc',
				key: 'Perf3M',
			},
			{
				header: `12 Month
				% Perf`,
				accessor: 'Perf12M',
				isHtmlContent: true,
				sortDir: 'desc',
				key: 'Perf12M',
			},
		];

		return (
			<Table
				data={data}
				columns={columns}
				isSortable={true}
				sortingInfo={sortingInfo}
				sortDataHandler={sortDataHandler}
			/>
		);
	};

	const getContent = () => {
		return (
			<Container
				id="peers-container"
				applyWhiteBackground
				applyBorder={!isMobileView}
				applyPadding={!isMobileView}
			>
				<div>
					{!isMobileView && (
						<Heading testId={'todays-performers'} content={'Peer Comparison'} />
					)}
					{renderHtml()}
				</div>
				{Object.values(peersData)?.length === 1 && (
					<div className="no-peer-label">
						<Label
							isGrey
							text={`There are no peers for ${props.symbol}.`}
						></Label>
					</div>
				)}
				{Object.values(peersData)?.length != 0 && (
					<Label isGrey text={delayedMessage()}></Label>
				)}
			</Container>
		);
	};

	return (
		<>
			{!isMobileView && getContent()}
			{isMobileView && (
				<Collapsible title="Peer Comparison">{getContent()}</Collapsible>
			)}
		</>
	);
};

export default Peers;
